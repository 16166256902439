<template>
  <div>
    <div class="mb-4">
      <router-link :to="{ name: 'PracticesList' }">
        <v-icon small class="mr-2">fa-angle-left</v-icon>
        <span>Все практики</span>
      </router-link>
    </div>
    <validation-observer v-slot="{ handleSubmit }">
      <item-edit-view
        title="Практика"
        :subtitle="item ? item.name ? item.name.ru : ''  : null"
        :loading="loading"
      >
        <template v-slot:right>
          <div>
            <v-btn
              right
              color="success"
              :loading="saving"
              @click.prevent="handleSubmit(save)"
            >
              <v-icon small>mdi-content-save</v-icon>
              Сохранить
            </v-btn>
          </div>
        </template>

        <v-card v-if="!loading && item">

          <v-tabs v-model="tab" background-color="primary" dark>
            <v-tab key="page">Страница</v-tab>
            <v-tab key="contacts">Контакты</v-tab>
            <v-tab key="management">Руководители</v-tab>
            <v-tab key="other">Разное</v-tab>
          </v-tabs>

          <v-card-text>
            <v-tabs-items v-model="tab">
              <v-tab-item key="page">
                <div class="d-flex align-center justify-space-between">
                  <div>
                    <v-checkbox v-model="status" label="Активно" />
                  </div>
                </div>


                <h2 class="mb-4">Название практики</h2>
                <locale-field
                  rules="required"
                  component="v-text-field"
                  name="name"
                  placeholder="Введите название практики"
                  dense
                  hide-details
                  outlined
                  v-model="item.name"
                  class="mb-12"
                />

                <v-row class="mb-8">
                  <v-col cols="5">

                    <h2 class="mb-4">
                      Meta
                    </h2>

                    <item-meta v-model="item.meta" class="mb-8"/>

                    <locale-field
                      name="metaDescription"
                      component="v-textarea"
                      auto-grow
                      dense
                      outlined
                      hide-details
                      :rows="2"
                      v-model.trim="item.metaDescription"
                      label="Description"
                    />
                  </v-col>
                  <v-col>
                    <clinic-sharing-preview
                      class="cv__share"
                      :title="item.meta.title && item.meta.title.ru ? item.meta.title.ru : item.name.ru"
                      :description="item.metaDescription.ru"
                      :image="item.socialImage"
                      @input:image="item.socialImage = $event"
                    />
                  </v-col>
                </v-row>

                <validation-provider slim rules="required" v-slot="{ errors }">
                  <slug-editor
                    class="mt-8 mb-8"
                    v-model="item.slug"
                    name="uri"
                    :input="item.name ? item.name.ru : ''"
                    label="Slug"
                    placeholder="Slug"
                    :error="errors[0]"
                    persistent-hint
                    hide-details="auto"
                  />
                </validation-provider>

                <clinic-hero
                  @input="({ desktop, mobile }) => {
                    item.mainSquarePhoto = mobile
                    item.mainWidePhoto = desktop
                  }"
                  :value="{
                    desktop: item.mainWidePhoto,
                    mobile: item.mainSquarePhoto,
                  }"
                />

                <div class="mt-12 mb-12">
                  Фото для карточки практики
                  <image-select v-model="item.cardImage" style="max-width: 200px;" />
                  <image-requirements size="534px" ratio="1/0.81" class="caption" />
                </div>

                <div class="text-h5 mt-12 mb-4">
                  Блоки
                </div>
                <practice-blocks v-model="item" />
              </v-tab-item>
              <v-tab-item key="contacts">
                <clinic-contacts v-model="item.contacts" />
              </v-tab-item>

              <v-tab-item key="management" eager>
                <clinic-managers-provider
                  v-model="item.generalManagers"
                  v-slot="{ pending }"
                >
                  <div v-if="pending">{{ $t('notes.loading') }}</div>
                  <clinic-managers-editor v-else v-model="item.generalManagers" class="mb-12" />
                </clinic-managers-provider>
              </v-tab-item>

              <v-tab-item key="other">
                <div>
                  <v-text-field
                    v-model="item.airtableId"
                    label="Airtable Id"
                    outlined
                    dense
                  />
                </div>
              </v-tab-item>

            </v-tabs-items>
          </v-card-text>
        </v-card>
      </item-edit-view>
    </validation-observer>
  </div>
</template>

<script>
import createOrUpdateViewMixin from '@/mixins/createOrUpdateView'
import ItemEditView from '@/components/ItemEditView'
import LocaleField from '@/components/Locale/LocaleField.vue'
import SlugEditor from '@/components/Slug/SlugEditor.vue'
import { practicesService, STATUS_PUBLISHED, STATUS_DRAFT } from '@/services'
import { QUILL_BASIC } from '@/components/elements/quilljs/configs'
import { PracticeBlocks } from '@/components/Practice'
import ClinicSharingPreview from '@/components/Clinic/ClinicSharingPreview.vue'
import ClinicHero from '@/components/Clinic/ClinicHero.vue'
import ImageSelect from '@/components/elements/image-select.vue'
import ClinicContacts from '@/components/Clinic/ClinicContacts.vue'
import { ItemMeta } from '@/components'
import ClinicManagersEditor from '@/components/Clinic/ClinicManagersEditor.vue'
import ClinicManagersProvider from '@/components/Clinic/ClinicManagersProvider.vue'

export default {
  components: {
    ClinicManagersEditor,
    ClinicManagersProvider,
    ItemMeta,
    ClinicContacts,
    ClinicSharingPreview,
    ItemEditView,
    LocaleField,
    SlugEditor,
    PracticeBlocks,
    ClinicHero,
    ImageSelect,
  },
  mixins: [
    createOrUpdateViewMixin(practicesService)
  ],
  computed: {
    status: {
      get () {
        return this.item.status === STATUS_PUBLISHED
      },
      set (value) {
        this.item.status = value ? STATUS_PUBLISHED : STATUS_DRAFT
      }
    }
  },
  data () {
    return {
      tab: null,
      QUILL_BASIC,
      STATUS_PUBLISHED,
    }
  }
}
</script>
